<template>
    <footer class="footer-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="footer-copyright">
                        <p>{{ year }} ©<a href="https://starsender.online/">Starsender</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import moment from 'moment'

export default {
    components: { 
    },
    data(){
        return{
            year: ""
        }
    },
    mounted () {
        this.year = moment().format("YYYY")
    },
    methods:{

    }
}
</script>