import axios from 'axios';

const state = {
    order_online: "",
};

const getters = {

};

const actions = {
    order_online_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('order_onlines', { params: data })
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_add({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('order_online', data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_edit({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('order_online/' + data.id, data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('order_online/' + data.id)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('order_online/' + data.id)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_sync({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.patch('order_online/' + data.id + "/sync", data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_unsync({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.patch('order_online/' + data.id + "/unsync", data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_getter({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('gateway/order_online/getter', data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_activate_autoresponder({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('gateway/order_online/activate_autoresponder', data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_online_deactivate_autoresponder({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('gateway/order_online/deactivate_autoresponder', data)
            .then(response => {
                commit("PUSH_OO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_OO(state, data){
        state.order_online = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};