import axios from 'axios';

const state = {
    withdraw: "",
};

const getters = {
};

const actions = {
    withdraw_request({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('withdraw', data)
            .then(response => {
                commit("PUSH_WITHDRAW", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_WITHDRAW(state, data){
        state.withdraw = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};