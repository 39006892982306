import axios from 'axios';

const state = {
    campaign: "",
};

const getters = {

};

const actions = {
    campaign_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaigns', { params: data })
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_list_all({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('campaigns_list_all')
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_message_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.id + "/messages",  {params: data})
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_user_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.id + "/users", {params: data})
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('campaign', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('campaign/' + data.id, data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_update_form({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('campaign/' + data.id + '/form', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_update_integration({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('campaign/' + data.id + '/integration', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('campaign/' + data.id)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.id)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_welcome_message({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.id + '/welcome-message')
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_welcome_message_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('campaign/' + data.id + '/welcome-message', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_message_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('campaign/' + data.id + '/messages', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_message_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('campaign/' + data.campaign_id + '/messages/' + data.id, data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_get_message({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.campaign_id + '/messages/' + data.id)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_user_bulk({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('campaign/' + data.campaign_id + '/users-bulk', data)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_user_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('campaign/' + data.campaign_id + '/users/' + data.id)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_message_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('campaign/' + data.campaign_id + '/messages/' + data.id)
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_history_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/' + data.id + "/history", {params: data})
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    campaign_repair({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('campaign/fix-broken')
            .then(response => {
                commit("PUSH_CAMPAIGN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CAMPAIGN(state, data){
        state.campaign = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
