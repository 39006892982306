import axios from 'axios';

const state = {
    agency: "",
};

const getters = {

};

const actions = {
    // admin_analytics({ commit }) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('/admin/analytics')
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_setting({ commit }) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('/admin/setting')
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_setting_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('/admin/setting', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_announcement_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('/admin/announcement', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_popup_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('/admin/popup', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // statistics_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/statistics', { params: data })
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_coupons_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/coupons', { params: data })
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_coupon_create({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.post('admin/coupon', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_coupon_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('admin/coupon/' + data.id, data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_coupon_detail({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/coupon/' + data.id)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_coupon_delete({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.delete('admin/coupon/' + data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_packages_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/packages', { params: data })
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_package_create({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.post('admin/package', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_package_detail({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/package/' + data.id)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_package_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('admin/package/' + data.id, data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_message_template_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/template-messages', { params: data })
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_message_template_detail({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/template-message/' + data.id)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_message_template_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('admin/template-message/' + data.id, data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // withdraw_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('admin/withdraws', { params: data })
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // withdraw_approve({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.patch('admin/withdraw/approve/' + data.id)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // withdraw_reject({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.patch('admin/withdraw/reject/' + data.id)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    // admin_user_message_list({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.post('admin/users/messages', data)
    //         .then(response => {
    //             commit("PUSH_AGENCY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    agency_user_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/users', { params: data })
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_one({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/user/' + data.id)
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('agency/user', data)
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('agency/user/' + data.user.id, data)
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('agency/user/' + data.id)
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_setting({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/setting')
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_setting_user({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/setting/user')
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_setting_edit({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('agency/setting', data)
            .then(response => {
                commit("PUSH_AGENCY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_AGENCY(state, data){
        state.agency = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};