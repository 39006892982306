import axios from 'axios';

const state = {
    link_rotator: "",
};

const getters = {

};


const actions = {
    linkrotator_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/linkrotator', data)
            .then(response => {
                commit("PUSH_LINKROTATOR", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    // autoreply_update({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.put('/autoreply/' + data.id, data)
    //         .then(response => {
    //             commit("PUSH_AUTOREPLY", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    linkrotator_check_short_url({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/linkrotator/urlcheck', data)
            .then(response => {
                commit("PUSH_LINKROTATOR", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    linkrotator_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('linkrotators', { params: data })
            .then(response => {
                commit("PUSH_LINKROTATOR", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    linkrotator_history_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('linkrotator/' + data.id + '/history', { params: data })
            .then(response => {
                commit("PUSH_LINKROTATOR", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    linkrotator_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('/linkrotator/' + data.id)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    linkrotator_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('/linkrotator/' + data.id, data)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    linkrotator_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('/linkrotator/' + data.id)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_LINKROTATOR(state, data){
        state.link_rotator = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};