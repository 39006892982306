import axios from 'axios';

const state = {
    package: "",
    cart: [],
};

const getters = {
    getCart (state){
        return state.cart
    },
};

const actions = {
    // outgoing_send({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.post('outgoing/send', data)
    //         .then(response => {
    //             commit("PUSH_MESSAGE", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    package_list({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('packages')
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    package_list_agency({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/packages')
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    // outgoing_export({ commit }) {
    //     return new Promise((resolve, reject) => { 
    //         axios.get('outgoing/export')
    //         .then(response => {
    //             commit("PUSH_MESSAGE", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
    package_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('package/' + data.id)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    package_buy({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('package/'+data.id+'/buy', data)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    coupon_check({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('coupon/check', data)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    payment_channel({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('payment/channel')
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    payment_topup({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('payment/topup', data)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    transaction_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('payment/transactions', { params: data })
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    package_sync({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('package/sync', data)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    // outgoing_delete({ commit }, data) {
    //     return new Promise((resolve, reject) => { 
    //         axios.delete('outgoing/' + data.id,)
    //         .then(response => {
    //             commit("PUSH_MESSAGE", "OK")
    //             resolve(response)
    //         })
    //         .catch(err => {
    //             reject(err)
    //       })
    //     })
    // },
}

const mutations = {
    PUSH_PACKAGE(state, data){
        state.package = data
    },
    PUSH_CART(state, data){
        state.cart = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};