import axios from 'axios';

const state = {
    autoreply: "",
};

const getters = {

};

const actions = {
    autoreply_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/autoreply', data)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_create_bulk({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('/autoreply/bulk', data)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('/autoreply/' + data.id, data)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('autoreplys', { params: data })
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_export({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('autoreplys/export')
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('/autoreply/' + data.id)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    autoreply_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('/autoreply/' + data.id)
            .then(response => {
                commit("PUSH_AUTOREPLY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_AUTOREPLY(state, data){
        state.autoreply = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};