import axios from 'axios';

const state = {
    history: "",
    contacts: [],
    chats: [],
    chat_lists: []
};

const getters = {

};

const actions = {
    history_chat_lists({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('history/chat-lists/' + data.id)
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    history_chats({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('history/chats/' + data.id + '/' + data.jid)
            .then(response => {
                commit("PUSH_HISTORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_HISTORY(state, data){
        state.history = data
    },
    PUSH_HISTORY_CONTACTS(state, data){
        state.contacts = data
    },
    PUSH_CHAT_LIST(state, data){
        state.chat_lists = data
    },
    PUSH_HISTORY_CHATS(state, data){
        state.chats = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};