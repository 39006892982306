<template>
  <div id="app">
    <div v-if="isLoggedIn && !fullscreen">
      <div class="layout-light side-menu overlayScroll">
        <div class="mobile-search"></div>
        <div class="mobile-author-actions"></div>
        <Header />
        <main class="main-content">
          <Aside />
          <router-view />
          <Footer />
        </main>
      </div>
      <Floating />
    </div>
    <div v-else-if="isLoggedIn && fullscreen">
      <router-view />
    </div>
    <div v-else-if="!isLoggedIn">
      <div class="layout-light side-menu">
        <main class="main-content">
          <router-view />
        </main>
      </div>
    </div>


  </div>
</template>

<script>
  import Aside from '@/components/layouts/Aside.vue'
  import Header from '@/components/layouts/Header.vue'
  import Footer from '@/components/layouts/Footer.vue'
  import Floating from '@/components/layouts/Floating.vue'

  export default {
    components: {
      Aside,
      Header,
      Footer,
      Floating
    },
    watch: {
      '$route'(to) {
        if (to.meta?.fullscreen) {
          this.fullscreen = true
        } else {
          this.fullscreen = false
        }

        document.title = to.meta.title + " - Starsender V3" || 'Starsender V3'
      }
    },
    data() {
      return {
        connection: null,
        isLoggedIn: this.$store.state.auth.isLoggedIn,
        fullscreen: false
      }
    },
    created() {

    },
    mounted() {
      if (localStorage.getItem('socket_token')) {
        //console.log('socket_token :'+localStorage.getItem('socket_token'))
        this.initWS()
      }

      this.$root.$on("logout", () => {
        this.isLoggedIn = false
      })

      this.$root.$on("login", () => {
        this.isLoggedIn = true
      })
    },
    methods: {
      sendToWS(message) {
        this.connection.send(message)
      },
      initWS() {
        var ws = new WebSocket(process.env.VUE_APP_WS_URL)
            
        this.connection = ws
        var t = this

        this.connection.onopen = function () {
          let initRequest = {
            event: "init",
            socket_token: localStorage.getItem('socket_token')
          }
          ws.send(JSON.stringify(initRequest))
        }

        setInterval(function () {
          ws.send("ping")
        }, 14000);

        var root = this.$root
        this.connection.onmessage = function (event) {
       // this.connection.onmessage = (event) => {
          //console.log(event.data)
          root.$emit("ws", event.data)
        }

        this.connection.onclose = function () {
          setTimeout(function () {
            t.initWS();
          }, 2500);
          //console.log(event)
        }
      },
    }
  }
</script>

<style>
  .spinner {
    height: 1rem !important;
    width: 1rem !important;
  }

  .vm--modal {
    height: auto !important;
    max-height: 95% !important;
    overflow-y: auto !important;
  }

  @media only screen and (max-device-width: 610px) {
    .vm--modal {
      width: 94% !important;
      margin-left: 3% !important;
      margin-right: 3% !important;
    }
  }

  hr {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
</style>