import axios from 'axios';

const state = {
    CDN: [],
    // isLoggedIn: false,
};

const getters = {
    // isLoggedIn (state){
    //     return state.isLoggedIn
    // },
    // user (state) {
    //     return state.user
    // }
};

const actions = {
    cdn_upload({commit}, data) {
        return new Promise((resolve, reject) => { 
            axios.post(process.env.VUE_APP_CDN_URL + '/api/v1/cdn/upload', data)
            .then(response => {
                commit("UPLOADS_CDN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    UPLOADS_CDN (state, data) {
        state.CDN = data
    },
    // set_role (state, data) {
    //     state.role = data
    // },
    // reset_user (state) {
    //     state.user = null
    //     state.isLoggedIn = false
    //     state.role = null
    // }
};

export default {
  state,
  getters,
  actions,
  mutations
};