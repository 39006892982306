<template>
    <header class="header-top">
        <nav class="navbar navbar-light">
            <div class="navbar-left">
                <a v-on:click="toggleSidebar" class="sidebar-toggle">
                    <img class="svg" src="@/assets/img/svg/bars.svg" alt="img">
                </a>
                <a class="navbar-brand" href="#">
                    <img class="svg dark" src="@/assets/img/starsender.webp" alt="">
                    <img class="light" src="@/assets/img/starsender.webp" alt="">
                </a>
            </div>
            <div class="navbar-right">
                <ul class="navbar-right__menu">
                    <li class="nav-author">
                        <div v-on:click="dropdownToggle" class="dropdown-custom" style="cursor:pointer">
                            <a><img src="@/assets/img/author-nav.png"
                                    alt="" class="rounded-circle"></a>
                            <div class="dropdown-wrapper">
                                <div class="nav-author__info">
                                    <div class="author-img">
                                        <img src="@/assets/img/author-nav.png" alt="" class="rounded-circle">
                                    </div>
                                    <div>
                                        <h6>{{ user.username }}</h6>
                                    </div>
                                </div>
                                <div class="nav-author__options">
                                    <ul>
                                        <li>
                                            <router-link to="/profile">
                                                <span class="nav-icon fas fa-user"></span> Profile
                                            </router-link>
                                        </li>
                                    </ul>
                                    <a v-on:click="logout" style="cursor:pointer" class="nav-author__signout"><span
                                            data-feather="log-out"></span> Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </header>

</template>

<script>
export default {
    components: { 
    },
    data(){
        return{
          user: this.$store.state.auth.user,
        }
    },
    mounted () {

    },
    methods:{
        async toggleSidebar(){
            this.$root.$emit("toggle_sidebar", true)
        },
        async dropdownToggle(){
            document.querySelector(".dropdown-wrapper").classList.toggle("show-wrapper");
        },
        async logout(){
            await this.$store.dispatch('auth_logout')
            this.$root.$emit("logout", true)
            this.$toast.success("Logout success")
            this.$router.push('/login')
        }
    }
}
</script>


<style scoped>
@media only screen and (max-width: 767px) {
  .navbar {
    padding: 0 1rem !important;
  }
}

@media only screen and (min-width: 767px) {
  .navbar {
    padding: 0 30px !important;
  }
}
</style>