import axios from 'axios';

const state = {
    chat_widget: "",
};

const getters = {

};

const actions = {
    chat_widget_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat_widgets', { params: data })
            .then(response => {
                commit("PUSH_CHAT_WIDGET", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chat_widget_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('chat_widget', data)
            .then(response => {
                commit("PUSH_CHAT_WIDGET", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chat_widget_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('chat_widget/' + data.id, data)
            .then(response => {
                commit("PUSH_CHAT_WIDGET", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chat_widget_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('chat_widget/' + data.id)
            .then(response => {
                commit("PUSH_CHAT_WIDGET", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chat_widget_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat_widget/' + data.id)
            .then(response => {
                commit("PUSH_CHAT_WIDGET", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CHAT_WIDGET(state, data){
        state.chat_widget = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};