import axios from 'axios';

const state = {
    device: "",
};

const getters = {

};

const actions = {
    device_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('device', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_relog({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('device/restart', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('/devices', { params: data })
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_list_all({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('devices', { params: data })
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_list_webhook({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('gateway/device/list/webhook')
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('device/' + data.id)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_log({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('gateway/device/log', { params: data })
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_regenerate({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('device/' + data.id + "/regenerate")
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id, data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_move({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id + '/move-server', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_update_webhook({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id + '/webhook', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_update_webhook2({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id + '/webhook2', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_update_webhook3({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id + '/webhook3', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_set_jam_kerja({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id + '/jam-kerja', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_logout({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('device/logout', data)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('device/' + data.id)
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    device_kosongkan_storage_s3({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('device/' + data.id+'/hapus-quota-media')
            .then(response => {
                commit("PUSH_DEVICE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_DEVICE(state, data){
        state.device = data
    },
    DELETE_DEVICE(state, data){
        state.device = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
